import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import CadastroDeLeadsContent from "@/components/CadastroDeLeadsContent";
import MenuContextProvider from "@/context/menu-context";
import SearchContextProvider from "@/context/search-context";


const CadastroDeLeads = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Cadastro de Leads">
          <CadastroDeLeadsContent />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default CadastroDeLeads;
