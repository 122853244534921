/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"

import LogoRedeBertoni from '../assets/images/lp/home/logo-rede-bertoni.svg'

import { 
  logoRede
}  from '../assets/styles/LP.styles'

import { 
  politicaPage,
}  from '../assets/styles/Other.styles'

const NotFoundContent = () => {
  return (
    <Fragment>
      <div css={logoRede}>
            <img src={LogoRedeBertoni} />
          </div>
      <section css={politicaPage} style={{ background: '#ffffff' }}>
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <iframe src="https://forms.monday.com/forms/embed/937e7363972de8f8ecaff5978830a9be?r=use1" style={{ width: '100%', height: 2250, border: 'none' }}  />
          </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
    
  );
};

export default NotFoundContent;
